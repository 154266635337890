<template>
    <ValidationProvider :vid="vid" :rules="rules" mode="passive" :name="name || label" v-slot="{ errors }">
        <div :class="$style.radioBox">
            <label :class="$style.label">
                <RadioGroup v-model="innerValue" :disabled="disabled" @on-change="onChange" >
                    <Radio label="0" :disabled="disabled" >
                        Morning
                    </Radio>
                    <Radio label="1" :disabled="disabled">
                       Evening
                    </Radio>
                </RadioGroup>
                <span>
                    <span v-if="isRequired && showAsterisk" :class="$style.required">*</span>
                    <slot />
                </span>
            </label>
            <div :class="$style.error" v-if="errors[0]">
                {{ errors[0] }}
            </div>
        </div>
    </ValidationProvider>
</template>

<script>

   
    import { RadioGroup, Radio } from 'view-design'
    import validationMixin from 'Mixins/validationMixin'

    export default {
        name: "FormLeaveType",
        mixins: [validationMixin],
        props: {
            value: [String, Number, Boolean],
            label: String,
            trueValue: [String, Number],
            falseValue: [String, Number],
            name: String,
            disabled: Boolean,
            showAsterisk: {
                type: Boolean,
                default: true
            },
            
        },
        components: {
            RadioGroup,
            Radio
        },
        methods:{
            onChange(val){
                this.$emit('onChangeEvent',val);
            }
        }
    }
</script>

<style lang="scss" module>
    .required {
        color: #ff3547;
        margin-right: 2px;
    }
    .error {
        color: #ff3547;
        position: absolute;
        max-width: 100%;
        left: 0;
        font-size: 11px;
        line-height: 13px;
        bottom: -15px;
        font-weight: 400;
        display: contents;
    }
    .radioBox {
        // position: relative;
        display: inline-grid;
        padding: 17px 0px 0px 22px;
    }
</style>
