<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Apply For Short Leave" slot="title" link="/helpContent/applyForShortLeave" />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            label="Reason Category"
            :items="reasonList"
            item-name="reason"
            item-value="ID"
            v-model="form.reasonId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <inputDate label="Date" v-model="form.date" rules="required" />
        </div>
        <!-- <div class="col-sm-2">
          <InputTime
            label="Start Time"
            v-model="form.startTime"
            rules="required"
          />
        </div>

        <div class="col-sm-2">
          <InputTime label="End Time" v-model="form.endTime" rules="required" />
        </div> -->
        <div class="col-sm-2" style="display: contents;">
          <!-- <InputYesNo
              :Yes="1"
              :No="0"
              name="Voucher"
              v-model="form.voucher"
              rules="required"
            /> -->
          <SLType v-model="form.SLType"  rules="required"/>
        </div>
      </FormRow>
      <FormRow>        <div class="col-sm-5">
          <inputTextArea label="Reason" v-model="form.reason" rules="required" />
        </div></FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <!-- <FormRow>
      <div class="col-md-12">
        <h3>Leave Matrix</h3>
        <br />
        <DataTable
          :actions="true"
          :data="leaveMatrixData"
          :columns="tableConfig"
          :is-loading="isLoading"
        >
        </DataTable>
      </div>
    </FormRow> -->
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
      <FormButton
        
        @click="()=>{$router.go(-1)}"
          >Ok</FormButton
        ></div> 
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { leaveMatrix, applyForSL, leaveReason,shortLeaveList } from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import SLType from "../../../components/SLType";
// import tableConfig from "./table";
// import DataTable from "Components/DataTable";

export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    // DataTable,
    ValidationObserver,
    SLType
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        date: "",
        startTime: "",
        endTime: "",
        reason: "",
        reasonId: null,
        id:null,
        SLType:'',
      },
      // tableConfig,
      // leaveMatrixData: [],
      reasonList: [],
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},

  created() {
    this.getLeaveRecord();
  },
  methods: {
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
      this.showMessage.isVisible = false;
      this.infoMessage.isVisible = false;
      const data = {
        date: this.form.date,
        // startTime: this.form.startTime,
        // endTime: this.form.endTime,
        reason: this.form.reason,
        reasonId: this.form.reasonId,
        id:this.form.id,
        SLType:this.form.SLType,
      };

      applyForSL(data).then((res) => {

        if (res.data[0].status > 1) {
          this.infoMessage.isVisible = true;
          this.infoMessage.message = res.data[0].message;
        } else {
          this.form = {};
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
        }
      });
    }
          });
    },
    getLeaveRecord() {
      leaveReason().then((res) => {
        this.reasonList = res.data;
      });
      // leaveMatrix().then((res) => {
      //   this.leaveMatrixData = res.data;
      // });
      if (this.$route.params.id) {
      this.form.id=atob(this.$route.params.id);
     this.getData();
    }
    },
    getData(){
      const filters = {};
      filters.id=this.form.id
      const data = {
        filterjson: {
          filter: [filters],
        },
      };
      shortLeaveList(data).then(res=>{
        this.form.date=res.data[0].LeaveDatstartforsort;
        // this.form.startTime=res.data[0].SLStartTime;
        // this.form.endTime=res.data[0].SLEndTime;
        this.form.reason=res.data[0].Reason;
        this.form.reasonId=res.data[0].LeaveReason_id;
        this.form.id=res.data[0].id;
        this.form.SLType=res.data[0].SLType;
      })
    },
  },
};
</script>
<style lang="scss">
    textarea{
        height: 180px !important;
    }
</style>